<template>
  <div class="orderHistory">
    <mt-header :title="$t('orderHistory')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <ul>
        <li v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/apply/orderDetails', query: { contractNo: item.applyId } })">
          <div class="wrap">
            <div class="val">{{ item.applyAmount | formatMoney }}</div>
            <div class="name">{{ $t('amount') }}</div>
          </div>
          <div class="wrap">
            <div class="val">{{ item.applyDate }}</div>
            <div class="name">{{ $t('applicationDate') }}</div>
          </div>
          <img class="arrow" src="../../assets/item_arrow_icon.png" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import callPhone from '@/components/callPhone.vue'
import { contractList } from '../../utils/api'
export default {
  name: 'OrderHistory',
  components: {
    callPhone
  },
  data() {
    return {
      loading: false,
      list: [],
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.loadMore()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async loadMore() {
      this.loading = true;
      await this.$axios({
        method: 'post',
        url: contractList,
      })
        .then((e) => {
          if (e.status.code == '000') {
            for (let i = 1; i <= 10; i++) {
              this.list = e.body;
            }
            this.loading = false;
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.orderHistory {
  background-color: #18252A;
  ul,li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    flex-grow: 1;
    position: relative;
    padding: 10px;
    li {
      display: flex;
      text-decoration: none;
      border-radius: 12px;
      margin: 10px 0px;
      padding: 20px 0px;
      background-color: white;
      align-items: center;
      justify-content: space-between;
      .arrow {
        height: 18px;
        margin-left: 10px;
        margin-right: 10px;
      }
      .status {
        margin-left: 10px;
        padding: 6px 10px;
        border-radius: 6.5px;
        background: #58C013;
        color: white;
      }
    }
    .wrap {
      background: #fff;
      border-radius: 10px;
      margin-left: 10px;
      text-align: center;
      .name {
        color: #757575;
        font-size: 12px;
      }
      .val {
        color: #000;
        font-size: 15px;
        color: #383838;
        font-weight: bold;
      }
    }
    .title {
      display: flex;
      align-items: center;
      height: 45px;
      color: #111111;
      font-size: 18px;
      img {
        width: 20px;
        margin: 0 15px;
      }
      div {
        color: #000;
        padding-left: 20px;
      }
    }
    .row {
      display: flex;
      height: 30px;
      color: #A8A8A8;
      font-size: 14px;
      font-weight: bold;
      align-items: center;
    }
  }
}
</style>
